// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod --configuration=production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    environment: 'PROD',
    plugApiUrl: 'https://data.corelab.com',
    apiUrl: 'https://rapidapi.corelab.com/',
    imagesUrl: 'https://data.corelab.com/DECT/',
    vrsUrl: 'https://vrs.corelab.com/pls/vrsshrd/',
    callingUrl: 'https://data.corelab.com/',
    callingUrlCT: 'https://data.corelab.com',
    returnUrlCT: 'https://data.corelab.com/account/signin?ReturnUrl=/#',
    xValue: 'R@pid$9102',
    colors: [
        '#fc0000',
        '#a8054c',
        '#590228',
        '#eb8034',
        '#ebc634',
        '#824342',
        '#c0eb34',
        '#68eb34',
        '#34eb93',
        '#aab094',
        '#34ebdf',
        '#34c3eb',
        '#348feb',
        '#3465eb',
        '#C03B39',
        '#4f34eb',
        '#8934eb',
        '#c934eb',
        '#5d3666',
        '#57455c',
        '#4c4d69',
        '#024511',
        '#6e2510',
        '#5F0B09',
        '#FE7103',
        '#AA7449',
        '#A08D4D',
        '#939A0A',
        '#EBF350',
        '#57A708',
        '#457F4B',
        '#457F72',
        '#14ECBB',
        '#11614E',
        '#98C7BC',
        '#0EB5F3',
        '#4693B0',
        '#476672',
        '#0E6EF1',
        '#3C6DB1',
        '#92B1DA',
        '#0C207A',
        '#6B77AF',
        '#4E6AE8',
        '#3A23E3',
        '#463E85',
        '#6C31C6',
        '#390C7E',
        '#9B81C2',
        '#B319E1',
        '#7D0B9E',
        '#CF86E4',
        '#E818C9',
        '#A5098D',
        '#F98EE9',
        '#920650',
        '#94456F',
        '#F474B8',
        '#070707',
        '#CD9BA9',
    ],
};
