<div class="toolbar">
  <div class="custom-toolbar">
    <button
    style="height: 60px;
    width: 240px;
    padding: 0;
    border: 0;"
    kendoButton
    [svgIcon]="menuSvg"
    fillMode="flat"
    (click)="drawer.toggle()"
  >
    <img style="height: 60px;width: 156px;opacity: 100%;" src="../assets/inc/images/rapid_logo_white.svg" height="100"
    width="100"/>
  </button>
  </div>
  <div class="banner">
    <app-banner [wellHeader]="wellHeader">
    </app-banner>
  </div>
</div>
<kendo-drawer-container>
  <kendo-drawer
    #drawer
    [items]="menuItems"
    mode="push"
    [expanded]="true"
    (select)="onSelect($event)"
    [autoCollapse]="false"
    [isItemExpanded]="isItemExpanded"
  >
  <ng-template kendoDrawerFooterTemplate>
    <div class="corelablogo">
    <img src="../assets/inc/images/clb_logo_wht_txt.svg" height="100"
    width="100"/>
    </div>
  </ng-template>
  </kendo-drawer>

  <kendo-drawer-content>
    <div class="content-display">
      <app-breadcrumb class="breadcrumb" [breadCrumbItems]="breadCrumbItems">
      </app-breadcrumb>
      <div *ngIf="isOverview && !isProduction">
        <app-well-overview></app-well-overview>
      </div>
      <div *ngIf="!isOverview" style="width: 100%;margin: 0 auto;height: 85%;position: relative;">
        <div style="height: 100%; margin-left: 30px; margin-right: 30px;">
          <iframe class="data-report-display" [src]="urlSafe"></iframe>
        </div>
      </div>
      <app-footer class="footer"></app-footer>
    </div>
  </kendo-drawer-content>
</kendo-drawer-container>