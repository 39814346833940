<mat-toolbar *ngIf="!isHidden" color="primary" class="fixed-header">
  <mat-toolbar-row>
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      *ngIf="isHandset$ | async"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <span>Rapid NG&copy;</span>
    <button *ngIf="authuser && _environment != 'PROD'"  mat-button routerLink="/home">Chart</button>
    <a *ngIf="authuser && _environment != 'PROD'" mat-button href="{{ docUrl }}" target="_blank">API</a>
    <button *ngIf="authuser  && _environment != 'PROD'" mat-button routerLink="/ct-scan/ct-main?hidden=true&id=6689">
      CT-Scan
    </button>
    <span class="spacer"></span>
    <button *ngIf="authuser  && _environment != 'PROD'" mat-button>{{ authuser.username }}</button>
    <button *ngIf="!authuser" mat-button routerLink="/account/login">
      Login
    </button>
    <button *ngIf="authuser" mat-button (click)="logout()">Logout</button>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>
