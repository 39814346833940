<div style="margin-right: 10px;">
    <google-map height="350px"
    width="100%"
    [center]="position"
    [zoom]="zoom"
    [options]="mapOptions">
<map-marker #marker="mapMarker"
      [position]="position"></map-marker>
</google-map>
</div>
