<mat-card>
  <mat-card-title>
    <h2>RAPID Charts</h2>
    <h4>Click the following links to view charts after login:</h4>
  </mat-card-title>
  <mat-card-content>
      <ul>
          <li><a [routerLink]="['/well-data/well-base-cntrfg']" [queryParams]="{pwi_nmbr_n: '10001210',data_type_vc:'CNTRFG'}">Well - Centrifuge 1</a></li>
          <li><a [routerLink]="['/well-data/well-base-cntrfg']" [queryParams]="{pwi_nmbr_n: '10840146',data_type_vc:'CNTRFG'}">Well - Centrifuge 2</a></li>
          <li><a [routerLink]="['/well-data/well-base-cntrfg']" [queryParams]="{pwi_nmbr_n: '15862782',data_type_vc:'CNTRFG'}">Well - Centrifuge 3</a></li>
          <li><a [routerLink]="['/ct-scan/ct-main']" [queryParams]="{hidden: true,id:6689}">CTScan</a></li>
          <li><a [routerLink]="['/chart/adsorption-quantity-pressure']" [queryParams]="{test_id_n: '1229130499'}">Adsorption Quantity/Pressure</a></li>
          <li><a [routerLink]="['/chart/air-brine-capillary-pressure-centrifuge']" [queryParams]="{smpl_id_n: '213931',test_mthd_vc:'ARBC'}">Airbrine Capillary Pressure-Centrifuge</a></li>
          <li><a [routerLink]="['/chart/air-brine-capillary-pressure-porous-plate']" [queryParams]="{smpl_id_n: '71260',test_mthd_vc:'ARBN'}">Airbrine Capillary Pressure-Porous Plate</a></li>
          <li><a [routerLink]="['/chart/acoustic-velocity']" [queryParams]="{smpl_id_n: '29517'}">Acoustic Velocity</a></li>
          <li><a [routerLink]="['/chart/composite-plots']" [queryParams]="{smpl_id_n: '213924'}">Composite Plots</a></li>
          <li><a [routerLink]="['/chart/core-gamma-spectral']" [queryParams]="{pwi_nmbr_n: '12487775'}">Core Gamma Spectral</a></li>
          <li><a [routerLink]="['/chart/core-gamma-spectral']" [queryParams]="{pwi_nmbr_n: '31983491'}">Core Gamma Spectral TEST</a></li>
          <li><a [routerLink]="['/chart/core-graph']" [queryParams]="{pwi_nmbr_n: '10001330'}">Correlation Core Graph</a></li>
          <li><a [routerLink]="['/chart/core-graph']" [queryParams]="{pwi_nmbr_n: '12487775'}">Correlation Core Graph TEST</a></li>
          <li><a [routerLink]="['/chart/cumulative-intrusion-pore-throad-radius']" [queryParams]="{smpl_id_n: '213924'}">Cumulative Intrusion Pore Throad Radius</a></li>
          <li><a [routerLink]="['/chart/deviatory-stress-strain']" [queryParams]="{test_id_n: '67514'}">Deviatory Stress/Strain</a></li>
          <li><a [routerLink]="['/chart/dynamic-mechanical-properties-porosity-vp']" [queryParams]="{pwi_nmbr_n: '10000739'}">Dynamic Mechanical Properties - Porosity/Vp Transit Time</a></li>
          <li><a [routerLink]="['/chart/dynamic-mechanical-properties-porosity-vs']" [queryParams]="{pwi_nmbr_n: '10002409'}">Dynamic Mechanical Properties - Porosity/Vs Transit Time</a></li>
          <li><a [routerLink]="['/chart/dynamic-mechanical-properties-vp-vs']" [queryParams]="{pwi_nmbr_n: '10002409'}">Dynamic Mechanical Properties - Vp Transit Time/Vs Transit Time</a></li>
          <li><a [routerLink]="['/chart/dynamic-mechanical-properties-vp-bulk-modulus']" [queryParams]="{pwi_nmbr_n: '10002409'}">Dynamic Mechanical Properties - Vp Transit Time/Dynamic Bulk Modulus</a></li>
          <li><a [routerLink]="['/chart/dynamic-mechanical-properties-vp-shear-modulus']" [queryParams]="{pwi_nmbr_n: '10002409'}">Dynamic Mechanical Properties - Vp Transit Time/Dynamic Shear Modulus</a></li>
          <li><a [routerLink]="['/chart/dynamic-mechanical-properties-vp-young-modulus']" [queryParams]="{pwi_nmbr_n: '10002409'}">Dynamic Mechanical Properties - Vp Transit Time/Dynamic Young's Modulus</a></li>
          <li><a [routerLink]="['/chart/formation-resistivity-factor-cocw']" [queryParams]="{test_id_n: '16480502'}">Formation Resistivity Factor - CoCw</a></li>
          <li><a [routerLink]="['/chart/formation-resistivity-index']" [queryParams]="{smpl_id_n: '24244125',test_mthd_vc:'RIX2'}">Formation Resistivity Index</a></li>
          <li><a [routerLink]="['/chart/fracture-conductivity']" [queryParams]="{test_id_n: '1228938640'}">Fracture Conductivity Detail</a></li>
          <li><a [routerLink]="['/chart/grain-coating-distribution']" [queryParams]="{pwi_nmbr_n: '10010100'}">Grain Coating Distribution</a></li>
          <li><a [routerLink]="['/chart/height-above-free-water']" [queryParams]="{smpl_id_n: '24244339'}">Height Above Free Water</a></li>
          <li><a [routerLink]="['/chart/hydrogen-index-tmax']" [queryParams]="{pwi_nmbr_n: '31989665'}">Hydrogen Index Tmax</a></li>
          <li><a [routerLink]="['/chart/kerogen-quality']" [queryParams]="{pwi_nmbr_n: '31989663'}">Kerogen Quality</a></li>
          <li><a [routerLink]="['/chart/langmuir-isotherm']" [queryParams]="{smpl_id_n: '12063659', gas_type_vc: 'Methane'}">Langmuir Isotherm</a></li>
          <li><a [routerLink]="['/chart/mercury-injection-capillary-pressure']" [queryParams]="{smpl_id_n: '213924'}">Mercury Injection Capillary Pressure</a></li>
          <li><a [routerLink]="['/chart/mohr-coulomb']" [queryParams]="{test_id_n: '1228269845'}">Mohr Coulomb Failure Envelope</a></li>
          <li><a [routerLink]="['/chart/nanoperm']" [queryParams]="{test_id_n: '1229151259'}">Nano-Permeability</a></li>
          <li><a [routerLink]="['/chart/oil-brine-capillary-pressure-centrifuge']" [queryParams]="{smpl_id_n: '24244205',test_mthd_vc:'ORBC'}">Oil Brine Capillary Pressure-Centrifuge</a></li>
          <li><a [routerLink]="['/chart/oil-brine-capillary-pressure-mercury-injection']" [queryParams]="{smpl_id_n: '24248371',test_mthd_vc:'ORBN'}">Oil Brine Capillary Pressure-Mercury Injection</a></li>
          <li><a [routerLink]="['/chart/other-laboratory-systems']" [queryParams]="{smpl_id_n: '24244339'}">Other Laboratory Systems</a></li>
          <li><a [routerLink]="['/chart/particle-size-analysis']" [queryParams]="{test_id_n: '156799'}">Particle Size Analysis</a></li>
          <li><a [routerLink]="['/chart/petrographic-point-count']" [queryParams]="{pwi_nmbr_n: '10001193'}">Petrographic Point Count</a></li>
          <li><a [routerLink]="['/chart/pore-volume-pore-diameter']" [queryParams]="{test_id_n: '1229130487'}">Pore Volume/Diameter</a></li>
          <li><a [routerLink]="['/chart/porosity-gas-permeability']" [queryParams]="{pwi_nmbr_n: '10000708'}">Porosity/Gas Permeability</a></li>
          <li><a [routerLink]="['/chart/porosity-klin-permeability']" [queryParams]="{pwi_nmbr_n: '10000714'}">Porosity/Klin Permeability</a></li>
          <li><a [routerLink]="['/chart/proppant-embedment']" [queryParams]="{test_id_n: '1229048350'}">Proppant Embedment</a></li>
          <li><a [routerLink]="['/chart/pseudo-van-krevelen']" [queryParams]="{pwi_nmbr_n: '31989663'}">Pseudo Van Krevelen</a></li>
          <li><a [routerLink]="['/chart/relative-permeability-gas-oil']" [queryParams]="{smpl_id_n: '71614',test_mthd_vc:'GORU'}">Relative Permeability: Gas/Oil</a></li>
          <li><a [routerLink]="['/chart/relative-permeability-gas-water']" [queryParams]="{smpl_id_n: '307869',test_mthd_vc:'GWRS'}">Relative Permeability: Gas/Water</a></li>
          <li><a [routerLink]="['/chart/relative-permeability-oil-water']" [queryParams]="{smpl_id_n: '24244179',test_mthd_vc:'WORU'}">Relative Permeability: Water/Oil</a></li>
          <li><a [routerLink]="['/chart/scrolling-core']" [queryParams]="{pwi_nmbr_n: '12487775'}">Scrolling Core</a></li>
          <li><a [routerLink]="['/chart/sara-composition-bar']" [queryParams]="{pwi_nmbr_n: '27703991',depth_n:'9500'}">Sara Composition - Bar</a></li>
          <li><a [routerLink]="['/chart/sara-composition-pie']" [queryParams]="{pwi_nmbr_n: '27703991',depth_n:'9500'}">Sara Composition - Pie</a></li>
          <li><a [routerLink]="['/chart/sidewall-porosity-permeability']" [queryParams]="{pwi_nmbr_n: '10000708'}">Sidewall Porosity/Permeability</a></li>
          <li><a [routerLink]="['/chart/static-mechanical-properties-ccs-sym']" [queryParams]="{pwi_nmbr_n: '31989663'}">Static Mechanical Properties - Confined Compressive Strength/Static Young's Modulus</a></li>
          <li><a [routerLink]="['/chart/stressed-permeability-brine']" [queryParams]="{smpl_id_n: '223474'}">Stressed Permeability - Brine</a></li>
          <li><a [routerLink]="['/chart/stressed-permeability-gas']" [queryParams]="{smpl_id_n: '223474'}">Stressed Permeability - Gas</a></li>
          <li><a [routerLink]="['/chart/stressed-permeability-klin-steady']" [queryParams]="{smpl_id_n: '47437541'}">Stressed Permeability Klin - Steady</a></li>
          <li><a [routerLink]="['/chart/stressed-porosity-gas']" [queryParams]="{smpl_id_n: '47437541'}">Stressed Porosity - Gas</a></li>
          <li><a [routerLink]="['/chart/t1t2-heatmap']" [queryParams]="{pre_test_id_n: '1228128974',post_test_id_n:'1228128973'}">T1/T2 Heatmap</a></li>
          <li><a [routerLink]="['/chart/t1t2-heatmap-d3']" [queryParams]="{pre_test_id_n: '1228128974',post_test_id_n:'1228128973'}">T1/T2 Heatmap - 3D</a></li>
          <li><a [routerLink]="['/chart/whole-core']" [queryParams]="{pwi_nmbr_n: '10015380'}">Whole Core Porosity/Permeability</a></li>
          <li><a [routerLink]="['/chart/xray-diffraction-clayfraction']" [queryParams]="{pwi_nmbr_n: '11389910'}">XRay Diffraction - Clay Fraction</a></li>
          <li><a [routerLink]="['/chart/xray-diffraction-wholerock']" [queryParams]="{pwi_nmbr_n: '10002882'}">XRay Diffraction - Whole Rock</a></li>
          <li><a [routerLink]="['/well-data/well-base']" [queryParams]="{pwi_nmbr_n: '12487951',data_type_vc:'HGCP'}">Well Base</a></li>
          <!--<li><a [routerLink]="['/well-data/well-base']" [queryParams]="{pwi_nmbr_n: '10001725',data_type_vc:'HGCP',edge_job_id_vc:''}">Well Base</a></li>
      <li><a [routerLink]="['/chart/test-view']">Test View</a></li>
    <li><a [routerLink]="['/chart/bulk-density']">Bulk Density</a></li>-->
          <!--<li><a [routerLink]="['/chart/core-log']" [queryParams]="{start_depth: '1000',end_depth:'1002'}">Core Log</a></li>
    -->
      </ul>
  </mat-card-content>
</mat-card>
